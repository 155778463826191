import { For } from 'solid-js';
import { theme } from '@troon/tailwind-preset/theme';
import { addToast, Button } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { Icon } from '@troon/icons';

export default function BorderRadiusStylePage() {
	return (
		<>
			<Title>Border radius / Styleguide</Title>
			<h1 class="mb-4 text-4xl font-semibold">Border radius</h1>

			<ul class="grid grid-cols-2 gap-8 sm:grid-cols-4">
				<For each={Object.entries(theme!.borderRadius!)}>
					{([key, size]) => (
						<li class="flex flex-col gap-2">
							<h2 class="flex flex-row-reverse items-center justify-end gap-4">
								border-{key}
								<Button
									appearance="transparent"
									class="flex size-full w-auto shrink grow-0 flex-col flex-nowrap items-start justify-center gap-2 overflow-hidden p-2 text-lg"
									onClick={() => {
										window.navigator.clipboard.writeText(`border-${key}`);
										addToast(
											<>
												Copied <code class="font-mono">text-{key}</code> to clipboard!
											</>,
											{
												timeout: 2000,
												variant: 'positive',
											},
										);
									}}
								>
									<Icon name="copy" />
								</Button>
							</h2>
							<div class="flex flex-row gap-2">
								<div style={{ 'border-radius': `${size}` }} class="size-12 bg-neutral-700" />
								<span>
									{size} ({parseFloat(`${size}`.replace('rem', '')) * 16}px)
								</span>
							</div>
						</li>
					)}
				</For>
			</ul>
		</>
	);
}
